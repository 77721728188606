<template>
  <div>

    <HeaderMain />

    <!-- ЦЕНТР ПОДДЕРЖКИ -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum" style="grid-column-gap: unset;">
          <!-- блок 1 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc"
               class="grid-colum left w-100 gray-container"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
               max-height: 600px;
            ">
            <div class="w-layout-vflex item _1 w-100">
              <div class="w-100">
                <h1 class="heading">Отзывы<br>клиентов<br>о Броктон</h1>
                <div class="w-100 flex justify-end">
                  <img style="max-width: 70%; position: relative; right: -25px; margin-top: 25px;" alt="money-2" src="../../../../../public/images/reviews.png">
                </div>
              </div>
            </div>

            <div class="w-layout-hflex flex-item-best w-text">
              <h2 class="text-mini white bold">Мнение наших<br>пользователей</h2>
            </div>

          </div>


          <!-- блок 2 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum right w-100"
               style="
               border-radius: unset;
               padding: 25px;
               padding-bottom: 35px;
            ">

            <div class="w-layout-vflex item _1 w-100">
              <div class="text-mini semibold">Отзывы</div>

              <div class="w-layout-vflex gap-10" v-for="(review, index) in reviews">
                <hr v-if="index !== 0" class="bold w-100" style="margin-bottom: 20px;">

                <img src="../../../../../public/images/stars.svg" alt="stars">
                <h2 class="heading h2">{{review.shortReview}}</h2>
                <div class="text-mini semibold">{{review.name}}</div>

                <h2 class="text-mini white bold" style="margin-top: 0;">
                  {{review.fullReview}}
                </h2>
              </div>

              <div class="calculators color-2">
                <div class="flex-calculate">
                  <div class="calculate-block one" style="margin-bottom: 0;">
                    <div class="sum-top between direction-row">
                      <div class="text-mini black" style="line-height: 100%;">Сумма займа</div>
                      <div class="sum-result"><span id="calculator_sum" class="sum">10 000</span> <span class="rub">₽</span></div>
                    </div>
                    <div class="wrapper-block">
                      <div class="range-slaider w-embed">
                        <div class="calculator_bottom-slider slider-styled" id="slider-sum"></div>
                      </div>
                    </div>
                    <div class="alculator-results-block between direction-adaptive align-items-start" style="margin-top: 10px;">
                      <div>
                        <div class="text-mini">5000₽ - 30000₽</div>
                      </div>
                      <div style="display: flex; gap: 10px;">
                        <div class="text-mini black">Возвращаете</div>
                        <div class="result-sum">
                          <div class="text-mini sum red"><span id="sale-price" class="sale-prices">36 300</span> ₽</div>
                          <div class="text-mini sum green"><span id="price" class="price">30 000</span> ₽</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="calculate-block right" style="margin-bottom: 0;">
                    <div class="sum-top between direction-row">
                      <div class="text-mini black" style="line-height: 100%;">Срок займа</div>
                      <div class="sum-result"><span id="calculator_date" class="day-namber">91</span> дней</div>
                    </div>
                    <div id="w-node-_94cd84f4-706f-fb9f-9827-6f4f6393e98b-8870dddc" class="wrapper-block">
                      <div class="range-slaider w-embed">
                        <div class="calculator_bottom-slider" id="slider-period"></div>
                      </div>
                    </div>
                    <div class="alculator-results-block between direction-adaptive align-items-start" style="margin-top: 10px;">
                      <div>
                        <div class="text-mini">91-365 дней</div>
                      </div>
                      <div style="display: flex; gap: 10px;">
                        <div class="alculator-results-block">
                          <div class="text-mini black" style="line-height: 100%;">Дата возврата</div>
                          <div id="date" class="text-mini sum">24.10.2023</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-layout-hflex flex-item-best">
                  <a @click="clickOpenLabel1" class="button-bust w-button">Оформить заявку</a>
                  <link rel="prerender" @click="clickOpenLabel1">
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- Броктон В ТВОЁМ ТЕЛЕФОНЕ (2) -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-1-colum" style="grid-column-gap: unset;">
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               position: relative; overflow: hidden;
               padding: 25px 30px 35px 25px;
               border-radius: unset;
               background: linear-gradient(135deg, #005BFA 0%, #005BFA 32%, #CFDCF0 90%);
               background-position: 50% 0;
               background-size: 150% 150%;">
            <div class="w-layout-vflex tittle"
                 style="justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title w-100" style="z-index: 1;">
                <div class="flex w-100 space-between" style="gap: 10px;">
                  <h1 class="heading" style="color: #fff;">Броктон в твоём<br>телефоне</h1>
                  <img alt="install" src="../../../../../public/images/google_play.svg" class="install-image">
                </div>
              </div>

              <!-- Картинка в углу -->
              <img src="../../../../../public/images/money.png" alt="money"
                   class="money-width-2"
              >

              <!-- блок разных вариантов установки, появляется на мобилках -->
              <div class="w-layout-vflex flex-good hidden-install"
                   style="
                       align-items: end;
                       flex-flow: row;
                       z-index: 2;
                       justify-content: space-around;"
              >
                <div>
                  <img height="60px" alt="googleplay" src="../../../../../public/images/google_play.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer, HeaderMain } from '/src/app/shared/components';
import loadjs from 'loadjs';

export default {
  name: 'Main',
  data() {
    return {
      slider: null,
      slider2: null,
      currentTime: new Date(),
      reviews: [
        {
          name: 'Алексей',
          shortReview: 'Круто работают ребята',
          fullReview: 'Хотел сделать сюрприз своей жене на день рождения и купить ей долгожданный подарок, но не хватило собственных сбережений. Не знал, что делать, но друг посоветовал воспользоваться услугами микрозайма через один из онлайн-сервисов. Я зарегистрировался на сайте, заполнил заявку и почти сразу получил одобрение от МФО. Процесс получения займа занял всего несколько минут, и уже через 10 минут я получил необходимую сумму на карту.'
        },
        {
          name: 'Мария',
          shortReview: 'Отличный сервис',
          fullReview: 'Оформление прошло быстро и без проблем. Деньги пришли вовремя, что меня очень порадовало. Буду рекомендовать этот сервис всем своим друзьям и знакомым. Очень довольна результатом!'
        },
        {
          name: 'Иван',
          shortReview: 'Рекомендую всем',
          fullReview: 'Очень доволен работой компании. Получил займ буквально за 10 минут. Всё было максимально просто и удобно. Отличная поддержка клиентов и выгодные условия. Буду пользоваться снова при необходимости.'
        },
        {
          name: 'Елена',
          shortReview: 'Лучший выбор',
          fullReview: 'Воспользовалась услугами и осталась очень довольна. Процесс быстрый и удобный, никаких лишних вопросов и бумажной волокиты. Деньги пришли моментально, как и обещали. Сервис на высоте!'
        },
        {
          name: 'Дмитрий',
          shortReview: 'Надёжно и быстро',
          fullReview: 'Не думал, что всё будет так просто. Заполнил заявку и сразу получил деньги на карту. Вся процедура заняла буквально несколько минут. Очень удобно, особенно когда срочно нужны деньги. Рекомендую!'
        }
      ],
    };
  },
  components: {
    Footer,
    HeaderMain,
  },
  created() {
    setInterval(() => {
      this.currentTime = new Date();
    }, 60000); // Каждую минуту
  },
  computed: {
    futureTime() {
      // Создаем новый объект Date, добавляя 10 минут к текущему времени
      const futureTime = new Date(this.currentTime.getTime() + 10 * 60000); // 10 минут в миллисекундах

      // Форматируем время в формат hh:mm
      const hours = ('0' + futureTime.getHours()).slice(-2);
      const minutes = ('0' + futureTime.getMinutes()).slice(-2);

      // Возвращаем отформатированную строку времени
      return hours + ':' + minutes;
    }
  },
  methods: {
    clickOpenLabel1() {
      window.localStorage.setItem('sum', this.slider.noUiSlider.get());
      window.localStorage.setItem('date', this.slider2.noUiSlider.get());
      this.$router.push('/personal-data');
    },
    renderTime() {
      // Get the current time and add 10 minutes to it
      var currentTime = new Date();
      var futureTime = new Date(currentTime.getTime() + 10 * 60 * 1000);
      // Format the time as HH:MM
      var formattedTime = futureTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      // Update each element with the class 'timeLabel' with the formatted time
      $('.timelabel').text(formattedTime);
    },
    changeDate(date) {
      var Year = date.getFullYear();
      var Month = date.getMonth();
      var Day = date.getDate();

      var fMonth;
      // Преобразуем месяца
      switch (Month) {
        case 0:
          fMonth = "января";
          break;
        case 1:
          fMonth = "февраля";
          break;
        case 2:
          fMonth = "марта";
          break;
        case 3:
          fMonth = "апреля";
          break;
        case 4:
          fMonth = "мая";
          break;
        case 5:
          fMonth = "июня";
          break;
        case 6:
          fMonth = "июля";
          break;
        case 7:
          fMonth = "августа";
          break;
        case 8:
          fMonth = "сентября";
          break;
        case 9:
          fMonth = "октября";
          break;
        case 10:
          fMonth = "ноября";
          break;
        case 11:
          fMonth = "декабря";
          break;
      }
      return `${Day} ${fMonth} ${Year}`;
    },
    jqueryLoaded() {
      // создание бегунков
      this.slider = document.getElementById("slider-sum"); // бегунок суммы
      this.slider2 = document.getElementById("slider-period"); // бегунок дней

      let sumBlock = document.getElementById("calculator_sum");
      let dateBlock = document.getElementById("calculator_date");
      let salePriceBlock = document.getElementById("sale-price");
      let priceBlock = document.getElementById("price");
      let dateFinishBlock = document.getElementById("date");

      noUiSlider.create(this.slider, {
        start: 10000, // значение бегунка при загрузке
        connect: "lower",
        range: {
          min: 5000, // минимальное значение бегунка суммы
          max: 30000, // максимальное значение бегунка суммы
        },
        step:1000,
      });

      noUiSlider.create(this.slider2, {
        start: 91, // значение бегунка при загрузке
        connect: "lower",
        range: {
          min: 91, // минимальное значение бегунка дней
          max: 365, // максимальное значение бегунка дней
        },
      });

      // расчет даты и суммы при загрузке страницы
      let startSum = Math.round(this.slider.noUiSlider.get()); // получаем стартовое значение суммы
      let startDays = Math.round(this.slider2.noUiSlider.get()); // получаем стартовое значение дней
      let currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + startDays);

      // формула расчёта перечеркнутой суммы
      let salePrice = Math.round((startSum * 0.1 + startSum) * 1.1);

      dateFinishBlock.innerHTML = this.changeDate(currentDate);
      sumBlock.innerHTML = `${startSum.toLocaleString()}`;
      priceBlock.innerHTML = `${startSum.toLocaleString()}`;
      salePriceBlock.innerHTML = `${salePrice}`;

      // ивенты бегунков
      this.slider.noUiSlider.on("slide", function (values, handle) {
        let dataValue = Math.round(values[handle]);
        // формула расчёта перечеркнутой суммы
        let salePrice = Math.round((dataValue * 0.1 + dataValue) * 1.1);
        sumBlock.innerHTML = `${dataValue.toLocaleString()}`;
        priceBlock.innerHTML = `${dataValue.toLocaleString()}`;
        salePriceBlock.innerHTML = `${salePrice}`;
      });

      this.slider2.noUiSlider.on("slide", (values, handle) => {
        let dataValue = Math.round(values[handle]);
        let date = new Date();
        date.setDate(date.getDate() + dataValue);
        dateBlock.innerHTML = `${dataValue}`;
        dateFinishBlock.innerHTML = this.changeDate(date);
      });
    },
    removeOldScripts() {
      const scripts = document.querySelectorAll('script[src*="accordion.js"], script[src*="jquery-3.5.1.min.dc5e7f18c8.js"], script[src*="webflow.js"]');
      scripts.forEach(script => {
        script.parentNode.removeChild(script);
      });
    },
    reloadComponent() {
      this.$router.go(0);
    },
    loadScripts() {
      // Удаляем старые скрипты
      this.removeOldScripts();

      // Подключаем jQuery
      loadjs(
          "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
          {
            success: () => {
              console.log('jQuery loaded');

              // Подключаем Webflow
              loadjs('./js/webflow.js', {
                success: () => {
                  console.log('Webflow loaded');
                  this.initAccordions();
                  this.jqueryLoaded();

                  // Call the function initially to set the time
                  this.renderTime();
                  // Set an interval to update the time every 10 minutes
                  setInterval(this.renderTime, 1000);
                },
                error: () => {
                  console.error('Failed to load Webflow');
                }
              });

              // Подключаем аккордеоны
              const scriptUrl = 'https://cdn.jsdelivr.net/npm/@finsweet/attributes-accordion@1/accordion.js';
              const script = document.createElement('script');
              script.async = true;
              script.src = scriptUrl;
              document.head.appendChild(script);
              script.onload = () => {
                console.log('Accordion script loaded');
                this.initAccordions();
              };
            },
            error: () => {
              console.error('Failed to load jQuery');
            }
          }
      );
    },
    initAccordions() {
      if (typeof window.fsAttributes !== 'undefined' && window.fsAttributes.accordion) {
        window.fsAttributes.accordion.init();
      } else {
        console.log('Accordion init function not found');
      }
    }
  },
  mounted() {
    this.loadScripts();
  },

  activated() {
    this.loadScripts();
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style>
</style>
